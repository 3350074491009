
// vuex
import { mapGetters } from 'vuex';
// utils
import { strValidate } from '~/assets/js/utils/validate-utils';
// components
import VButton from 'landing/components/ui/button/VButton.vue';
import FormSocial from '~/components/common/FormSocial.vue';
import VTabBar from '~/components/ui/tabs/VTabBar.vue';
import VInput from '~/components/ui/input/VInput.vue';
import VIcon from '~/components/ui/icon/VIcon.vue';
import VImageSrcSet from '@@/common/components/ui/images/VImageSrcSet.vue';
import PolicyDisclaimer from 'landing/components/common/PolicyDisclaimer.vue';

// consts
import { legalInfo } from '../../assets/js/constants/templateText';

export default {
    name: 'SubscribeForm',

    components: {
        PolicyDisclaimer,
        VImageSrcSet,
        VIcon,
        VInput,
        VTabBar,
        FormSocial,
        VButton,
    },

    props: {
        offers: {
            type: Array,
            default: () => [],
        },

        activeTab: {
            type: [String, Number],
            default: null,
        },

        socials: {
            type: Array,
            default: () => [],
        },

        isModal: Boolean,
    },

    data() {
        return {
            currentTab: this.activeTab,
            values: {
                phone: '',
                email: '',
                full_name: '',
                website: '',
                position: '',
            },

            errors: {
                phone: '',
                email: '',
                full_name: '',
                website: '',
                position: '',
            },

            status: '',
        };
    },

    computed: {
        legalInfo() {
            return legalInfo;
        },

        ...mapGetters({
            isTestStage: 'domain/getIsTestStage',
        }),

        bgAttrs() {
            return this.isTestStage
                ? {
                    src: '/images/form/form-bg.png',
                }
                : {
                    sizes: 'mobile:100vw tablet:100vw laptop:100vw desktop:100vw',
                    image: '/images/form/form-bg.png',
                    twoSteps: true,
                };
        },

        activeOffer() {
            return this.offers.find(el => el.id === this.currentTab);
        },

        isBtnDisabled() {
            return Object.values(this.errors).some(el => el) || Object.values(this.values).some(el => !el);
        },

        tabs() {
            return this.offers.map(el => ({
                label: el.name,
                value: el.id,
            }));
        },
    },

    watch: {
        activeTab() {
            this.currentTab = this.activeTab;
        },
    },

    mounted() {
        this.currentTab = this.activeTab || this.tabs[0].value;
    },

    methods: {
        strValidate,

        async onSubmit() {
            try {
                await this.$axios.$post(this.$api.membershipRequest, {
                    offer: this.currentTab || '',
                    phone: this.values.phone || '',
                    email: this.values.email || '',
                    position: this.values.position || '',
                    website: this.values.website || '',
                    full_name: this.values.full_name || '',
                });

                this.status = 'success';
            } catch (e) {
                console.warn('[SubscribeForm/onSubmit] request failed ', e);
                this.status = 'error';
            }
        },

        tryAgain() {
            this.values = {
                phone: '',
                email: '',
                full_name: '',
                website: '',
                position: '',
            };
            this.status = '';
        },
    },
};
