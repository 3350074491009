
import VTab from '~/components/ui/tabs/VTab.vue';

export default {
    name: 'VTabBar',

    components: {
        VTab,
    },

    props: {
        /**
         * HTML тег - обертка для табов
         */
        tag: {
            type: String,
            default: 'ul',
            validator: v => [
                'ul',
                'div',
            ].includes(v),
        },

        /**
         * Массив всех табов, каждый из которых может содержать параметры описанные в VTab,
         * а также обязательные параметры label и value
         */
        tabs: {
            type: Array,
            default: () => [],
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'small',
            validator: value => ['small'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'grey',
            validator: value => ['grey', 'transparent'].includes(value),
        },

        value: {
            type: [String, Number, Boolean],
            default: null,
        },

        relative: Boolean,
    },

    data() {
        return {
            //
        };
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style._relative]: this.relative,
            };
        },

        isComponent() {
            return this.tag;
        },

        passedProps() {
            return {
                size: this.size,
                color: this.color,
                relative: this.relative,
            };
        },

        currentTab() {
            if (typeof this.value === 'undefined' || this.value === null) {
                return {};
            }

            return {
                value: this.value,
                ind: this.tabs.findIndex(el => el.value === this.value),
            };
        },
    },

    watch: {
        close() {
            this.$nextTick(() => {
                this.calcBg();
            });
        },

        size() {
            this.$nextTick(() => {
                this.calcBg();
            });
        },

        slider(val) {
            if (val) {
                this.$nextTick(() => {
                    this.calcBg();
                });
            }
        },

        currentTab: {
            deep: true,
            handler() {
                this.calcBg();
            },
        },
    },

    mounted() {
        setTimeout(this.calcBg, 500);
    },

    methods: {
        calcBg() {
            this.bgSliderWidth(this.currentTab.ind);
            this.bgSliderOffset(this.currentTab.ind);
        },

        bgSliderWidth(ind) {
            const tabWidth = this.$refs?.tabs[ind || 0]?.$el.getBoundingClientRect()?.width;

            if (this.$refs.bgSlider?.style) {
                this.$refs.bgSlider.style.width = `${tabWidth}px`;
            }
        },

        bgSliderOffset(ind) {
            let tabsWidth = 0;

            for (let index = 0; index < (ind || 0); index++) {
                tabsWidth += this.$refs.tabs[index].$el.getBoundingClientRect().width;
            }


            if (this.$refs.bgSlider?.style) {
                this.$refs.bgSlider.style.left = `${tabsWidth}px`;
            }
        },
    },
};
