// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VTabBar_O\\+Aot{display:flex;position:relative;-webkit-user-select:none;user-select:none}.VTabBar_O\\+Aot._relative_\\+mgzE{width:100%}.VTabBar_O\\+Aot._grey_DCZ2m .slider_CeHKc,.VTabBar_O\\+Aot._transparent_ndv-H .slider_CeHKc{background-color:#9d67f9;border-color:#9971ff;box-shadow:inset 0 0 .3rem 0 #804dff,0 0 .4rem 0 #a76effb3,0 0 .4rem 0 #804dffb3,0 0 .4rem 0 #804dffb3,0 0 .2rem 0 #a76effb3}.slider_CeHKc{border:1px solid #0000;height:100%;left:0;margin:0;min-width:1rem;position:absolute;top:0;transition:.3s ease;z-index:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"VTabBar": "VTabBar_O+Aot",
	"_relative": "_relative_+mgzE",
	"_grey": "_grey_DCZ2m",
	"slider": "slider_CeHKc",
	"_transparent": "_transparent_ndv-H"
};
module.exports = ___CSS_LOADER_EXPORT___;
