// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VTab_W30\\+Q{border:1px solid #0000;cursor:pointer;display:flex;justify-content:center;position:relative;text-transform:uppercase;transition:color .3s ease,background-color .3s ease,border-color .3s ease,opacity .3s ease;-webkit-user-select:none;user-select:none;white-space:nowrap}.VTab_W30\\+Q._relative_jwt\\+D{width:100%}.VTab_W30\\+Q._disabled_xIvYx{opacity:.5;pointer-events:none}.VTab_W30\\+Q._small_rpWEB{font-size:1rem;font-weight:500;line-height:1.16rem;padding:1.4rem 3rem;text-transform:uppercase}.VTab_W30\\+Q._grey_XjP9V{background-color:#161119;border-color:#0000;color:#b6b4b7}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.VTab_W30\\+Q._grey_XjP9V:hover{background-color:#3a363d;color:#fff}}.VTab_W30\\+Q._grey_XjP9V._active_bTyqp:not(._colorOnly_mSmQl){background-color:#9d67f9;border-color:#9971ff;color:#fff}.VTab_W30\\+Q._grey_XjP9V._colorOnly_mSmQl._active_bTyqp{background-color:#0000;border-color:#0000;color:#fff}.VTab_W30\\+Q._transparent_W1Duc{background-color:#0000;border-color:#0000;color:#b6b4b7}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.VTab_W30\\+Q._transparent_W1Duc:hover{color:#9971ff}}.VTab_W30\\+Q._transparent_W1Duc._active_bTyqp:not(._colorOnly_mSmQl){background-color:#9d67f9;border-color:#9971ff;color:#fff}.VTab_W30\\+Q._transparent_W1Duc._colorOnly_mSmQl._active_bTyqp{background-color:#0000;border-color:#0000;color:#fff}.content_3SC1\\+{position:relative;z-index:2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"VTab": "VTab_W30+Q",
	"_relative": "_relative_jwt+D",
	"_disabled": "_disabled_xIvYx",
	"_small": "_small_rpWEB",
	"_grey": "_grey_XjP9V",
	"_active": "_active_bTyqp",
	"_colorOnly": "_colorOnly_mSmQl",
	"_transparent": "_transparent_W1Duc",
	"content": "content_3SC1+"
};
module.exports = ___CSS_LOADER_EXPORT___;
