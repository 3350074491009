
export default {
    name: 'VTab',

    props: {
        active: Boolean,

        disabled: Boolean,

        tag: {
            type: String,
            default: 'div',
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'small',
            validator: value => ['small'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'grey',
            validator: value => ['grey', 'transparent'].includes(value),
        },

        relative: Boolean,
    },

    computed: {
        classList() {
            return {
                [this.$style._active]: this.active,
                [this.$style._disabled]: this.disabled,
                [this.$style._relative]: this.relative,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style._colorOnly]: this.$parent?.$el?.classList?.contains('v-tab-bar'),
            };
        },

        isComponent() {
            return this.tag;
        },
    },
};
